import React from "react";
import { LanguageSelector } from "../LanguageSelector";

import "./styles.scss";

export const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <p>
          {new Date().getFullYear()} © Passepartout4u P.IVA 07054850966 - All rights are reserved.
        </p>
        <div className="language-container">
          <span>Language:</span>
          <LanguageSelector />
        </div>
      </div>
    </footer>
  );
};
