import React, { useEffect, useState } from 'react'

import './styles.scss'

export const ScrollDown = () => {

  const [scroll, setScroll] = useState(true);


  const handleScroll = () => {
    const documentHeight = document.body.scrollHeight;
    const currentScroll = window.scrollY + window.innerHeight;
    const modifier = 200; 

    if(currentScroll + modifier > documentHeight) {
      setScroll(false);
    }
    else
      setScroll(true);
  }

  useEffect(() => {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return (
      <div className={'scrolldown-container ' + (scroll ? "visible" : "invisible") }>
        <div className='inner'>
        <div className='mouse'>
          <div className='scroller'></div>
        </div>
        <span>Scroll <span>Down</span></span>
        </div>
      </div>
  );
};
