import React, { Component } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

import "./slick.css";

export default class SliderHero extends Component {
  render() {
    var image1 = "../../../images/hero/Mandalaki_PROD.jpg";
    var image2 = "../../../images/hero/TomFord_ECOM.jpg";
    var image3 = "../../../images/hero/Behindthescene_INCF.jpg";
    var image4 = "../../../images/hero/Spinoff_PROD.jpg";
    var image5 = "../../../images/hero/TheAttico_ECOM.jpg";
    var image6 = "../../../images/hero/Lofficiellithuania_PROD.jpg";
    var image7 = "../../../images/hero/GoldenGoose_ECOM.jpg";

    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      swipeToSlide: true,
      fade: true,
      arrows: false,
      autoplaySpeed: 2000,
      cssEase: "ease-in-out",
    };

    return (
      <Slider {...settings}>
        <div className="slider-item">
          <StaticImage src={image1} alt="" />
          <div className="credits">
            <span>Mandalaki @ PPT4U Production</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image2} alt="" />
          <div className="credits credits--dark">
            <span>Tom Ford @ PPT4U E-commerce</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image3} alt="" />
          <div className="credits">
            <span>Behind the Scene @ inCf Studio</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image4} alt="" />
          <div className="credits">
            <span>The Spin off @ PPT4U Production</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image5} alt="" />
          <div className="credits credits--dark">
            <span>The Attico @ PPT4U E-Commerce</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image6} alt="" />
          <div className="credits">
            <span>L`officiel Lithuania @ PPT4U Production</span>
          </div>
        </div>
        <div className="slider-item">
          <StaticImage src={image7} alt="" />
          <div className="credits credits--dark">
            <span>Golden Goose @ PPT4U E-Commerce</span>
          </div>
        </div>
      </Slider>
    );
  }
}
