import React from 'react'
import { Header } from '../Header';
import { ScrollDown } from '../ScrollDown';

import './reset.css';
import './global.scss';

export const Layout = ({ children }) => {
  return (
    <div className='global-wrapper'>
      <Header/>
        <main>
        { children }
        <ScrollDown/>
        </main>
    </div>
  )
}
