import React, { useEffect } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./styles.scss";

export const Clients = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const elements = gsap.utils.toArray(".client-list li");
    gsap.set(elements, { autoAlpha: 0, y: 100 });

    if (elements) {
      ScrollTrigger.batch(".client-list li", {
        interval: 0.2,
        batchMax: 2,
        onEnter: (batch) =>
          gsap.to(batch, {
            autoAlpha: 1,
            stagger: 0.2,
            y: 0,
            duration: 1,
            ease: "back",
          }),
        start: "top bottom",
        end: "top top",
      });
    }
  }, []);

  return (
    <section className="section client-section">
      <div className="container">
        <div className="section-heading">
          <h1>
            <Trans>Clients</Trans>
          </h1>
        </div>

        <div className="client-wrapper">
          <ul className="client-list">
            <li>Anest</li>
            <li>Audepart</li>
            <li>Bally</li>
            <li>Brioni</li>
            <li>Bvlgari</li>
            <li>Carnale</li>
            <li>Celano Jewelry</li>
            <li>Dolce & Gabbana</li>
            <li>Dsquared2</li>
            <li>Febe</li>
            <li>Fred Perry</li>
            <li>Golden Goose</li>
            <li>Harper's Bazaar</li>
            <li>Hunter</li>
            <li>IWC Schaffhausen</li>
            <li>Kobf</li>
            <li>L`officiel Belgium</li>
            <li>L`officiel Italia</li>
            <li>L`officiel Lithuania</li>
            <li>L`officiel Mexico</li>
            <li>Lajma</li>
            <li>Le Mile</li>
            <li>Les Hommes</li>
            <li>Loungers</li>
            <li>Mandalaki</li>
            <li>Marie Claire</li>
            <li>Mirror Mirror</li>
            <li>Miu Miu</li>
            <li>Momo Design</li>
            <li>Moncler</li>
            <li>November Mag</li>
            <li>Neil Barrett</li>
            <li>Numero`</li>
            <li>Palm Angels</li>
            <li>Prada</li>
            <li>Ratti Botique</li>
            <li>Samuele Failli</li>
            <li>Santoni</li>
            <li>Seven Friday</li>
            <li>Superga</li>
            <li>The Attico</li>
            <li>Tom Ford</li>
            <li>Trussardi</li>
            <li>TSUM</li>
            <li>Varcity</li>
            <li>Vogue Arabia</li>
            <li>Vogue Italia</li>
            <li>Vogue Portugal</li>
          </ul>
        </div>
      </div>
    </section>
  );
};
