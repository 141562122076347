import { useStaticQuery, graphql } from "gatsby"

export const useServiceContent = () => {
  const { allServicesJson } = useStaticQuery(
    graphql`
      query SitePostWordpress {
        allServicesJson {
          edges {
            node {
              title
              content {
                paragraphs
                list
              }
              image{
                src{
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
                alt
              }
              reverse
              link
            }
          }
        }
      }
    `
  )

  return allServicesJson.edges
}