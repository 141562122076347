import React, { useEffect } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./styles.scss";
import video from "../../../images/incfMagazine.mp4";

export const Magazine = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".magazine-box",
          scrub: 1,
          pin: false,
          start: "top bottom",
          end: "top top",
        },
      })
      .to(".magazine-box", {
        clipPath: "inset(0% 0px round 0px)",
        ease: "none",
      });
  });

  useEffect(() => {
    const el = document.querySelector(".overlay .inner");
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center",
          end: "top center",
        },
      })
      .fromTo(
        ".overlay h1",
        { scale: 0, autoAlpha: 0 },
        { autoAlpha: 1, scale: 1 }
      )
      .fromTo(".overlay span", { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(".overlay a", { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.1 });
  }, []);

  return (
    <div className="section magazine-section">
      <div className="container container--full">
        <div className="magazine-box">
          <div className="inner">
            <div className="overlay">
              <div className="inner">
                <span>Issue N°1</span>
                <h1>inCf Magazine</h1>
                <a href="https://www.incfmagazine.com/" data-efx="magnet">
                  <Trans>Discover More</Trans>
                </a>
                <a
                  href="https://www.incfmagazine.com/prodotto/incf-n1-luca-santese/"
                  data-efx="magnet"
                >
                  <Trans>Buy Now</Trans>
                </a>
              </div>
            </div>
            <video loop={true} autoPlay muted playsInline>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};
