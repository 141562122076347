import React, { useEffect } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./styles.scss";
import SliderHero from "./slider";

export const Intro = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const el = document.querySelector(".hero-section");
    const tl = gsap
      .timeline({
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          ease: "ease-in-out",
        },
      })
      .fromTo(
        ".hero-section .left-col",
        { clipPath: "inset(80% 100% 80% 100%)" },
        { clipPath: "inset(0%)", duration: 1 }
      )
      .fromTo(
        ".hero-section h1",
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1, ease: "back" }
      )
      .fromTo(
        ".hero-section p",
        { autoAlpha: 0, y: -50 },
        { autoAlpha: 1, y: 0, duration: 1, ease: "back" }
      );
  }, []);

  return (
    <section className="hero-section">
      <div className="container container--full">
        <div className="left-col">
          <SliderHero />
        </div>

        <div className="right-col">
          <div className="inner">
            <h1>
              <Trans>
                A multifaceted organization able to combine full production
                management, creative direction, e-commerce and studio rental
                services.
              </Trans>
            </h1>
            <div className="txtcpy">
              <p>
                <Trans>
                  Our experienced team believes in the power of imagery and in a
                  smart, effective approach. The mission of Passepartout4u is
                  developing meaningful and bespoke communication projects,
                  enhancing the vision and the heritage of its clients through
                  high-quality content.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
