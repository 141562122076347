import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

import "./styles.scss";

export const Contact = () => {
  return (
    <section className="section contact-section">
      <div className="container">
        <div className="contact-us">
          <div className="section-heading">
            <h1>
              <Trans>Let's make a thing</Trans>
            </h1>
          </div>
          <a href="mailto:hello@ppt4ugroup.com" data-efx="magnet">
            hello@ppt4ugroup.com
          </a>
          <span>Via Vallarsa 10</span>
          <span>20139 Milano</span>
        </div>
      </div>
    </section>
  );
};
