import React from "react";
import { Link } from "gatsby";
import { LanguageSelector } from "../LanguageSelector";

import "./styles.scss";
import logo from "../../../images/logo.png";

export const Header = () => {
  return (
    <header className="site-header">
      <div className="container container--full">

        <Link className="logo-link" to={`/`}>
          <img src={logo} alt="Passepartout4u" className="logo" />
          <span className="logo-subtitle">Group</span>
        </Link>
        <div className="language-container">
          <LanguageSelector />
        </div>

      </div>
    </header>
  );
};
