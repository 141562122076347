import React, { useEffect } from "react";
import gsap from "gsap";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useServiceContent } from "../../../hooks/getServices";

import "./styles.scss";

export const Services = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.batch(".media-container", {
      toggleClass: "is-reveal",
      once: true,
      start: "150px center",
      end: "bottom center",
    });
  }, []);

  useEffect(() => {
    const elements = gsap.utils.toArray(".service-box");
    gsap.set(elements, { autoAlpha: 0, y: 100 });

    if (elements) {
      elements.forEach((el) => {
        ScrollTrigger.create({
          trigger: el,
          start: "top center",
          end: "bottom center",
          onEnter: () => {
            gsap.to(el, { autoAlpha: 1, y: 0, duration: 1, ease: "back" });
          },
        });
      });
    }
  }, []);

  useEffect(() => {
    const elements = gsap.utils.toArray('[data-efx="magnet"]');

    if (elements) {
      elements.forEach((el) => {
        el.addEventListener("mousemove", (e) => {
          const pos = el.getBoundingClientRect();
          const moveX = (e.clientX - pos.left - pos.width / 2) * 0.03333;
          const moveY = (e.clientY - pos.top - pos.height / 2) * 0.33333;

          el.style.transform = `translate(${moveX}%, ${moveY}%)`;
        });

        el.addEventListener("mouseout", (e) => {
          el.style.transform = `translate(0px , 0px)`;
        });
      });
    }
  }, []);

  const data = useServiceContent();
  const { t } = useTranslation();

  return (
    <section className="section service-section">
      <div className="container">
        {data.map((site, index) => {
          const rev = site.node.reverse;
          const paragraphs = site.node.content.paragraphs;
          const link = site.node.link;
          const list = site.node.content.list;
          const image = getImage(site.node.image.src);
          const imageAlt = site.node.image.alt;

          return (
            <div
              className={"service-box" + (rev ? " reversed" : "")}
              key={index}
            >
              <div className="right-col">
                <div className="media-container">
                  <GatsbyImage
                    image={image}
                    alt={imageAlt}
                    className="media-container__img"
                  />
                </div>
              </div>

              <div className="left-col">
                <div className="inner">
                  <h1>
                    <Trans>{site.node.title}</Trans>
                  </h1>
                  <div className="txtcpy">
                    {paragraphs.map((p, i) => {
                      return (
                        <p key={i}>
                          <Trans>{p}</Trans>
                        </p>
                      );
                    })}
                    <ul>
                      {list.map((item, index) => {
                        return (
                          <li key={index}>
                            <Trans>{item}</Trans>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="cta-button"
                    data-efx="magnet"
                  >
                    <Trans>Discover More</Trans>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
