import * as React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/common/Layout'
import Seo from '../components/common/Seo'
import { Intro } from '../components/landing/Intro'
import { Services } from '../components/landing/Services'
import { Clients } from '../components/landing/Clients'
import { Magazine } from '../components/landing/Magazine'
import { Contact } from '../components/landing/Contact'
import { Footer } from '../components/common/Footer'

const IndexPage = () => {
  return (
    <Layout>
      <Seo/>
      <Intro/>
      <Services/>
      <Magazine/>
      <Clients/>
      <Contact/>
      <Footer/>
    </Layout>
  )
}

export default IndexPage


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;